<template>
  <div class="content">
    <a-affix :offset-top="0">
      <Header title="我的钱包" bgColor="#e2bd7b" />
    </a-affix>
    <div class="top-img"></div>
    <div class="fins">
      <div class="fin" v-for="(v, k) in fins" :key="k">
        <div class="title">
          <div class="icon">
            <img src="@/assets/customer/qianbao.png" alt="" />
          </div>
          {{ v.customerMobile }}余额
        </div>
        <div class="value">{{ v.balance }}</div>
        <div
          class="pay"
          @click="
            $router.push({ name: 'customer-paycode', params: { id: v.id } })
          "
        >
          <QrcodeOutlined />
          <div class="title">支付</div>
        </div>
      </div>
    </div>

    <div class="records">
      <div class="record-title">账户明细</div>
      <div class="record-tab">
        <div class="tab-title">
          <div
            :class="{ title: true, on: tab === -1 }"
            @click="changeBillType(-1)"
          >
            全部
          </div>
          <div
            :class="{ title: true, on: tab === 0 }"
            @click="changeBillType(0)"
          >
            充值
          </div>
          <div
            :class="{ title: true, on: tab === 10 }"
            @click="changeBillType(10)"
          >
            消费
          </div>
        </div>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            class="list"
          >
            <div class="item" v-for="(v, k) in list" :key="k">
              <div class="title">
                <div>{{ v.billTypeCN }}</div>
                <div class="time">
                  {{ $filters.formatDate(v.createTime, "YYYY-MM-DD") }}
                </div>
              </div>
              <div class="money">
                <span>{{ v.symbol }}{{ v.money }}</span>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Header from './Header'
import { FinAccountClass } from '@/apis/fin'
import { BillClass } from '@/apis/bill'
import { QrcodeOutlined } from '@ant-design/icons-vue'

import { List, PullRefresh } from 'vant'
import 'vant/es/list/style'
import 'vant/es/pull-refresh/style'

const api = new FinAccountClass()
const bill = new BillClass()
export default defineComponent({
  components: {
    Header,
    QrcodeOutlined,

    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  setup () {
    const fins = ref([])
    const tab = ref(-1)

    const queryParams = ref({
      Limit: 20
    })
    const paginate = ref({
      total: 0,
      page: 1
    })
    const list = ref([])
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)

    const onGetPersonalFin = () => {
      api.getPersonals().then((resp) => {
        fins.value = resp
      })
    }

    const onLoad = () => {
      console.log('加载', paginate.value.total, paginate.value.page)
      if (
        paginate.value.page * queryParams.value.Limit >= paginate.value.total ||
        paginate.value.total <= queryParams.value.Limit
      ) {
        finished.value = true
      } else {
        getDataList(++paginate.value.page)
      }
    }

    const getDataList = (page) => {
      loading.value = true
      if (page === 1) {
        list.value = []
      }

      paginate.value.page = page
      const q = { ...queryParams.value }
      q.Offset = (page - 1) * q.Limit

      if (tab.value === -1) {
        delete q.BillType
      } else {
        q.BillType = tab.value
      }

      bill.getPersonals(q).then((resp) => {
        list.value.push(...resp.data)

        paginate.value.total = resp.total

        loading.value = false
        refreshing.value = false

        if (page >= resp.total) {
          finished.value = true
        } else {
          finished.value = false
        }
      })
    }

    const onRefresh = () => {
      refreshing.value = true
      getDataList(1)
    }

    const changeBillType = (e) => {
      tab.value = e
      getDataList(1)
    }

    getDataList(1)

    onGetPersonalFin()
    return {
      fins,
      queryParams,
      paginate,
      list,
      loading,
      finished,
      refreshing,
      tab,
      onLoad,
      onRefresh,
      getDataList,
      changeBillType
    }
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #f5f5f5;
  .top-img {
    background: url(../../assets/customer/my-bg.png) no-repeat bottom center;
    background-size: 100% auto;
    height: 3.2rem;
  }

  .fins {
    padding: 0 0.3rem;
    margin-top: -2rem;
    .fin {
      background: #fff;
      border-radius: 0.3rem;
      box-shadow: 0 0 0.1rem rgba(50, 50, 50, 0.1);
      padding: 0.2rem;
      position: relative;
      margin-bottom: .1rem;
      .title {
        display: flex;
        align-items: center;
        color: #ceb175;
        font-size: 0.24rem;
        height: 0.5rem;
        .icon {
          width: 0.37rem;
          height: 0.37rem;
          margin-right: 0.2rem;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .value {
        font-size: 0.6rem;
        color: #000000;
        line-height: 1rem;
      }

      .pay {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;
        font-size: 0.4rem;
        height: 100%;
        width: 1.5rem;
        .title {
          font-size: 0.24rem;
        }
      }
    }
  }

  .records {
    padding: 0 0.3rem;
    margin-top: 0.4rem;
    .record-title {
      font-size: 0.3rem;
      color: #000000;
      border-left: 0.08rem solid #ca984f;
      height: 0.3rem;
      line-height: 0.3rem;
      padding-left: 0.17rem;
    }
    .record-tab {
      background: #fff;
      border-radius: 0.3rem;
      box-shadow: 0 0 0.1rem rgba(50, 50, 50, 0.1);
      padding: 0.1rem 0;
      margin-top: 0.2rem;
      .tab-title {
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #ebebeb;
        line-height: 0.7rem;
        .title {
          text-align: center;
          font-size: 0.3rem;
          color: #333333;
          &.on {
            color: #ceb175;
            position: relative;
            &:after {
              content: "";
              position: absolute;
              bottom: -0.03rem;
              left: 0;
              width: 100%;
              height: 0.07rem;
              background: #ceb175;
              border-radius: 0.03rem;
            }
          }
        }
      }
      .list {
        padding: 0 0.5rem;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 1rem;
          border-bottom: 1px solid #ebebeb;
          margin-top: 0.4rem;
          .title {
            font-size: 0.24rem;
            color: #333333;
            .time {
              color: #999999;
            }
          }
        }
      }
    }
  }
}
</style>
